<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'SqlRunnerList' }">
              SQL 排程列表
            </b-breadcrumb-item>
            <b-breadcrumb-item active>查看 SQL 排程</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                查看 SQL 排程
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <sql-runner-form
                  :start-at="startAt"
                  :end-at="endAt"
                  :sql-runner="sqlRunner"
                  :is-readonly="true"
                ></sql-runner-form>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
                >返回
              </b-button>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { getHours, getMinutes } from "date-fns";
import sqlRunnerApi from "@/apis/sql-runner";
import SqlRunnerForm from "./SqlRunnerForm";

export default {
  components: {
    SqlRunnerForm,
  },
  data() {
    return {
      showLoading: false,
      sqlRunner: {
        description: null,
        cron_schedule: null,
        sql: null,
        format: null,
        start_at: null,
        end_at: null,
        mail_subject: null,
        mail_content: null,
        mail_to: null,
        mail_cc: null,
        mail_bcc: null,
        is_enabled: false,
        send_when_empty: false,
      },
      startAt: {
        date: new Date(),
        time: {
          HH: "00",
          mm: "00",
        },
      },
      endAt: {
        date: new Date(),
        time: {
          HH: "23",
          mm: "59",
        },
      },
    };
  },
  async created() {
    this.showLoading = true;
    await this.fetchSqlRunner();
    this.showLoading = false;
  },
  methods: {
    async fetchSqlRunner() {
      const response = await sqlRunnerApi.getSqlRunner(this.$route.params.sql_runner_id);
      this.sqlRunner = response.data.data;
      const { start_at, end_at } = response.data.data;
      this.startAt = {
        date: new Date(start_at),
        time: {
          HH: String(getHours(new Date(start_at))).padStart(2, "0"),
          mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
        },
      };
      this.endAt = {
        date: new Date(end_at),
        time: {
          HH: String(getHours(new Date(end_at))).padStart(2, "0"),
          mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
        },
      };
    },
    handleCancel() {
      this.$router.push({ name: "SqlRunnerList" });
    },
  },
};
</script>
